<template>
  <!--
  *@des:青年人才生活补贴（非全日制）
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="社会保障卡开户银行"
        placeholder="请输入社会保障卡开户银行"
        required
        maxlength="100"
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="社会保障卡金融账号"
        placeholder="请输入社会保障卡金融账号"
        required
        maxlength="20"
      />
      <van-field
        :value="domicileStr"
        name="domicileStr"
        clickable
        label="户籍地址"
        placeholder="请选择户籍地址"
        required
        readonly
        @click="showHousePicker = true"
      />
      <van-popup v-model="showHousePicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="houseCitys"
          @cancel="showHousePicker = false"
          @confirm="onDomicileConfirm"
        />
      </van-popup>

      <van-field-calendar
        v-model="form.domicileDate"
        name="domicileDate"
        label="户籍迁入日期"
        placeholder="户籍迁入日期"
        :fieldProps="{
          label: '户籍迁入日期',
          placeholder: '请选择',
          required: true,
        }"
      />
      <van-field
        v-model="form.workCmpy"
        name="workCmpy"
        label="工作单位"
        placeholder="请输入工作单位"
        required
        maxlength="100"
      />

      <!-- 学历信息 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >学历信息</van-divider
        >
        <van-field
          v-model="form.college"
          name="college"
          label="毕业院校"
          placeholder="请输入毕业院校"
          maxlength="100"
          required
        />
        <van-field
          v-model="form.major"
          name="major"
          label="所学专业"
          placeholder="请输入所学专业"
          maxlength="100"
          required
        />
        <van-field-select
          v-model="form.education"
          :options="educations"
          :fieldProps="{
            label: '学历',
            placeholder: '请选择学历',
            required: true,
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.educationType"
          :options="educationTypes"
          :fieldProps="{
            label: '学历形式',
            placeholder: '请选择学历形式',
            required: true,
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.degree"
          :options="degrees"
          :fieldProps="{
            label: '学位',
            placeholder: '请选择学位',
            required: true,
          }"
        ></van-field-select>
        <van-field-calendar
          v-model="form.graduateDate"
          name="graduateDate"
          label="毕业时间"
          placeholder="毕业时间"
          :fieldProps="{
            label: '毕业时间',
            placeholder: '请选择',
            required: true,
          }"
        />
      </div>
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";

export default {
  name: "young-life-subsidy",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        bankId: "", //社会保障卡开户银行
        bankAccount: "", //社会保障卡金融账号
        domicile: "", //户籍
        domicileDate: "", //迁入日期
        workCmpy: "", //工作单位
        //学历信息
        college: "",
        major: "",
        education: "",
        educationType: "",
        degree: "",
        graduateDate: "",
      },
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式",
          },
        ],
        bankId: {
          required: true,
          message: "请输入开户银行",
        },
        bankAccount: {
          required: true,
          message: "请输入金融账号",
        },
        domicile: {
          type: "array",
          required: true,
          message: "请选择户籍地址",
        },
        domicileDate: {
          required: true,
          message: "请选择迁入日期",
        },
        workCmpy: {
          required: true,
          message: "请输入工作单位",
        },
        college: {
          required: true,
          message: "请输入学校",
        },
        major: {
          required: true,
          message: "请输入专业",
        },
        education: {
          required: true,
          message: "请选择学历",
        },
        educationType: {
          required: true,
          message: "请选择学历形式",
        },
        degree: {
          required: true,
          message: "请选择学位",
        },
        graduateDate: {
          required: true,
          message: "请选择毕业时间",
        },
      },
      showHousePicker: false,
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
      ],
      houseCitys: [
        {
          value: "41",
          text: "河南省",
          children: [
            {
              value: "4101",
              text: "郑州市",
              children: [
                {
                  value: "410173",
                  text: "航空港区",
                },
                {
                  value: "410122",
                  text: "中牟县",
                },
                {
                  value: "410184",
                  text: "新郑市",
                },
              ],
            },
            {
              value: "4102",
              text: "开封市",
              children: [
                {
                  value: "410223",
                  text: "尉氏县",
                },
              ],
            },
          ],
        },
      ],
      educations: [
        {
          value: "大专",
          label: "大专",
        },
        {
          value: "本科",
          label: "本科",
        },
        {
          label: "研究生",
          value: "研究生",
        },
      ],
      educationTypes: [
        {
          value: "成人高考",
          label: "成人高考",
        },
        {
          label: "远程教育",
          value: "远程教育",
        },
        {
          label: "自学考试",
          value: "自学考试",
        },
        {
          label: "国家开放大学",
          value: "国家开放大学",
        },
        {
          label: "其他",
          value: "其他",
        },
      ],
      degrees: [
        {
          value: "硕士",
          label: "硕士",
        },
        {
          label: "博士",
          value: "博士",
        },
      ],
    };
  },
  computed: {
    domicileStr() {
      if (!this.form.domicile.length) {
        return "";
      } else {
        let [a, b, c] = this.form.domicile;
        let pro = this.houseCitys.filter((item) => item.value === a)[0];
        let city = pro.children.filter((item) => item.value === b)[0];
        let county = city.children.filter((item) => item.value === c)[0];
        return `${pro.text}/${city.text}/${county.text}`;
      }
    },
  },
  created() {
    this.initFormValidator();
  },
  methods: {
    /*
     *@des: 选择户籍
     */
    onDomicileConfirm(a, b) {
      let [index1, index2, index3] = b;
      this.form.domicile = [
        this.houseCitys[index1].value,
        this.houseCitys[index1].children[index2].value,
        this.houseCitys[index1].children[index2].children[index3].value,
      ];
      this.showHousePicker = false;
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      this.formValidator = new Schema(this.formRules);
    },
    /*
     *@des: 校验表单
     */
    validate() {
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(() => {
            resolve({
              ...this.form,
              proCode: this.form.domicile[0],
              cityCode: this.form.domicile[1],
              countyCode: this.form.domicile[2],
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>